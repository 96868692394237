/* eslint-disable @typescript-eslint/no-unused-vars */
import { MantineColor, MantineThemeColors, MantineThemeOverride } from "@mantine/core";
import dayjs from "dayjs";
import { Color } from "styles/index";
import "dayjs/locale/ru";
import * as fonts from "./fonts";

dayjs.locale("ru");

export const addMantineColor = (color: string): MantineThemeColors[MantineColor] => {
    return [color, color, color, color, color, color, color, color, color, color];
};

const { montserrat } = fonts;

export const defaultTheme: MantineThemeOverride = {
    colorScheme: "light",
    fontFamily: montserrat.style.fontFamily,
    primaryColor: "main_Black",
    black: Color.main_Black,
    white: Color.second_white,
    colors: {
        main_Black: addMantineColor(Color.main_Black),
        main_MainDark: addMantineColor(Color.main_MainDark),
        main_MainDarkGray: addMantineColor(Color.main_MainDarkGray),
        main_MainLightGray: addMantineColor(Color.main_MainLightGray),

        second_SecondDarkGray: addMantineColor(Color.second_SecondDarkGray),
        second_SecondLightGray: addMantineColor(Color.second_SecondLightGray),
        second_white: addMantineColor(Color.second_white),

        validation_violet: addMantineColor(Color.validation_violet),
        validation_blue: addMantineColor(Color.validation_blue),
        validation_green: addMantineColor(Color.validation_green),
        validation_red: addMantineColor(Color.validation_red),
    },
    defaultRadius: "sm",
    breakpoints: {
        xs: 576,
        sm: 745,
        md: 1025,
        lg: 1440,
        xl: 1920,
    },
    fontSizes: {
        xs: 10,
        sm: 14,
        md: 14,
        lg: 16,
        xl: 20,
    },
    headings: {
        fontFamily: montserrat.style.fontFamily,
        fontWeight: montserrat.style.fontWeight,

        sizes: {
            h1: {
                fontWeight: 600,
                fontSize: 32,
                lineHeight: "48px",
            },

            h2: {
                fontWeight: 600,
                fontSize: 24,
                lineHeight: "32px",
            },

            h3: {
                fontWeight: 600,
                fontSize: 20,
                lineHeight: "20px",
            },

            h4: {
                fontWeight: 600,
                fontSize: 16,
                lineHeight: "20px",
            },
        },
    },
    globalStyles: () => ({
        body: {
            overflowX: "hidden",
            marginRight: "calc(-1 * (100vw - 100%)) !important",
        },
        "::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 4,
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: Color.second_white,
            borderRadius: 2,
        },
    }),
    components: {
        Tooltip: {
            defaultProps: {
                events: {
                    hover: true,
                    touch: true,
                },
            },
        },
        Stack: {
            defaultProps: {
                spacing: 0,
            },
        },
    },
};
